<template>
  <!-- Form -->
  <form ref="authForm" class="mt-2" :class="{'was-validated': authFormValidated}" novalidate>
    <!-- Alert -->
    <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
    <!-- Mobile -->
    <div class="mb-3">
      <label for="mobile" class="form-label">{{ t('label.mobile') }}</label>
      <div class="input-group">
        <select ref="countryCodeSelect" style="max-width: 200px;" v-model="auth.region" class="form-select" required @change="onCountryCodeChange" pattern="">
          <option value="" disabled selected>{{ t('placeholder.country-code') }}</option>
          <option v-for="phoneCountryCode in phoneCountryCodes" :key="phoneCountryCode.region" :value="phoneCountryCode.region">{{ phoneCountryCode.country }}</option>
        </select>
        <input ref="mobileInput" v-model.trim="auth.mobile" type="mobile" id="mobile" class="form-control" name="mobile" :placeholder="t('placeholder.mobile')" required @input="onMobileInput">
        <it-button kind="primary" class="rounded-end" outline style="width: 140px;" :loading="sendingOtp" :disabled="disableOtpButton" @click="doSendAuthOtp">{{ optButtonText }}</it-button>
        <div class="invalid-feedback" :class="{invalid: countryCodeSelectInvalid}">{{ t('validator.invalidate-country-code') }}</div>
        <div class="invalid-feedback" :class="{invalid: mobileInputInvalid}">{{ t('validator.invalidate-mobile') }}</div>
      </div>
    </div>
    <!-- OTP -->
    <div class="mb-3">
      <label for="otp" class="form-label">{{ t('label.otp') }}</label>
      <input v-model.trim="auth.otp" type="text" id="otp" class="form-control" name="otp" :placeholder="t('placeholder.otp')" required pattern="\d{6}" />
      <div class="invalid-feedback">{{ t('validator.invalidate-otp') }}</div>
    </div>
    <!-- Button -->
    <div class="d-grid">
      <it-button kind="primary" :loading="authing" @click="doAuth">{{ t('button.auth') }}</it-button>
    </div>
  </form>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import Timer from 'tiny-timer'
import { inject, onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import authAPI from '../../api/auth'
import mobileAPI from '../../api/mobile'
import smsAPI from '../../api/sms'
import { toast } from '../../injection'
import { Actions, useStore } from '../../store'
import { ParentAuthed, ParentMobileOtpAuth } from '../../types/auth'
import { Result } from '../../types/common/result'
import { PhoneCountryCode } from '../../types/mobile'
import { getCountryCodeFromRegion } from '../../utils/mobileUtils'
import ItButton from '../ui/ItButton.vue'

const { t, locale } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const router = useRouter()
const store = useStore()

const phoneCountryCodes = ref<PhoneCountryCode[]>()
const doFindAllPhoneCountryCodes = function () {
  phoneCountryCodes.value = []
  const language = locale.value as string
  mobileAPI.findAllCountryCode(language).then((response: AxiosResponse<Result<PhoneCountryCode[]>>) => {
    const result = response.data
    if (result.success) {
      phoneCountryCodes.value = result.data
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

const message = ref<string>('')

const auth = reactive<ParentMobileOtpAuth>(new ParentMobileOtpAuth())
const authForm = ref<HTMLFormElement>()

const optButtonText = ref<string>(t('button.get-otp'))
const disableOtpButton = ref<boolean>(false)
const sendingOtp = ref<boolean>(false)
let countDown = 30
const timer = new Timer({ interval: 1000, stopwatch: false })
timer.on('tick', () => {
  optButtonText.value = Number(countDown--).toString() + 's'
})
timer.on('done', () => {
  optButtonText.value = t('button.get-otp')
  disableOtpButton.value = false
  countDown = 30
})

const countryCodeSelect = ref<HTMLSelectElement>()
const countryCodeSelectInvalid = ref<boolean>(false)
const onCountryCodeChange = function() {
  countryCodeSelect.value?.setCustomValidity('')
  countryCodeSelectInvalid.value = false

  setCountryCodeSelectStyle()
}

const mobileInput = ref<HTMLInputElement>()
const mobileInputInvalid = ref<boolean>(false)
const onMobileInput = function() {
  mobileInput.value?.setCustomValidity('')
  mobileInputInvalid.value = false
}

const doSendAuthOtp = function() {
  if (!countryCodeSelect.value?.checkValidity()) {
    countryCodeSelectInvalid.value = true
    return
  }

  if (!mobileInput.value?.checkValidity()) {
    mobileInputInvalid.value = true
    return
  }

  auth.countryCode = getCountryCodeFromRegion(phoneCountryCodes.value, auth.region)

  if (auth.countryCode && auth.mobile) {
    sendingOtp.value = true
    disableOtpButton.value = true
    smsAPI.sendAuthOtp(auth.countryCode, auth.mobile).then((response: AxiosResponse<Result<void>>) => {
      const result: Result<void> = response.data
      if (result.success) {
        toastRef?.value?.success(t('message.send-otp-success'))
        timer.start(countDown * 1000)
      } else {
        message.value = result.message
        disableOtpButton.value = false
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
      disableOtpButton.value = false
    }).finally(() => {
      sendingOtp.value = false
    })
  }
}

const authing = ref<boolean>(false)
const authFormValidated = ref<boolean>(false)
const doAuth = function() {
  authFormValidated.value = true
  if (authForm.value?.checkValidity()) {
    authing.value = true
    // 登录或注册
    auth.promoteCode = store.getters.promoteCode
    authAPI.mobileOtpAuth(auth).then((response: AxiosResponse<Result<ParentAuthed>>) => {
      const result = response.data
      if (result.success) {
        const authed = result.data
        const parent = authed.parent
        if (parent.locked) { // 账户锁定
          message.value = t('message.account-is-locked')
        } else {
          // 存储家长信息
          store.dispatch(Actions.AUTH, authed).then(() => {
            if (parent.complete) {
              // 同步语言
              if (locale.value !== parent.locale) locale.value = parent.locale
              store.dispatch(Actions.CHANGE_LOCALE, parent.locale)
              // 同步时区
              store.dispatch(Actions.CHANGE_ZONE_ID, parent.zoneId)
              router.push({
                name: 'schedule'
              })
            } else {
              router.push({
                name: 'supplement'
              })
            }
          })
        }
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      const response = error.response
      if (response) {
        if (response.status === 400) {
          message.value = t('message.otp-is-wrong')
        } else {
          message.value = error.message
        }
      } else {
        toastRef?.value?.error(error.message)
      }
    }).finally(() => {
      authing.value = false
    })
  }
}

const onLocaleChange = function() {
  optButtonText.value = t('button.get-otp')
  doFindAllPhoneCountryCodes()
}

const setCountryCodeSelectStyle = function() {
  if (countryCodeSelect.value) {
    if (auth.region) {
      countryCodeSelect.value.style.color = 'black'
    } else {
      countryCodeSelect.value.style.color = 'gray'
    }
  }
}

onMounted(() => {
  setCountryCodeSelectStyle()
  doFindAllPhoneCountryCodes()
})

defineExpose({
  onLocaleChange
})
</script>